import React, { useEffect } from 'react';
import { AppProps } from 'next/app';
import '@styles/globals.css';
import '../styles/index.css';
import 'react-dates/lib/css/_datepicker.css';
import CartRoomContextProvider from 'src/context/cartRoom';
import 'react-responsive-carousel/lib/styles/carousel.css';
import '@styles/animate.css';
import AuthContextProvider from 'src/context/authContext';
import 'react-dates/initialize';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { isMobile } from 'react-device-detect';
import { FooterDesktop, FooterMobile } from '@components/baseComponents/footer';
import { useRouter } from 'next/router';
import { appWithTranslation, Router } from '../../i18n';

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());
export function reportWebVitals(metric) {
  console.log(metric);
}
Router.events.on('routeChangeComplete', () => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
});

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const { order_id } = router.query;

  useEffect(() => {
    if (isMobile) {
      window.location.replace(process.env.MOBILE_REDIRECT);
    }
  }, [isMobile]);

  return (
    <AuthContextProvider>
      <CartRoomContextProvider>
        <PrivateRoute>
          <div id="parentAll">
            <Component {...pageProps} />
          </div>
        </PrivateRoute>

        {router.pathname === '/guest' ||
        router.pathname === '/' ||
        router.pathname === '/orders' ||
        router.pathname === '/orders/history' ||
        order_id ? (
          <>
            <div className="hidden md:block">
              {' '}
              <FooterMobile />{' '}
            </div>
            <div className="md:hidden">
              {' '}
              <FooterDesktop />
            </div>
          </>
        ) : (
          <></>
        )}
      </CartRoomContextProvider>
    </AuthContextProvider>
  );
}

export default appWithTranslation(MyApp);

function PrivateRoute(props) {
  useEffect(() => {
    const users = JSON.parse(localStorage.getItem('users'));
    if (window.location.pathname === '/orders' && !users) {
      Router.replace('/');
    } else if (window.location.pathname === '/checkout' && !users) {
      Router.replace('/');
    }
  }, []);
  return <div>{props.children}</div>;
}
