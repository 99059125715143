import React from 'react';

function FooterMobile() {
  return (
    <footer className="w-full footer px-0 pfooter items-center">
      <div className="flex flex-col items-center pt-10 ">
        <img src={process.env.LOGO_WHITE || '/img/logo-primary.svg'} className="flex items-center h-8 w-auto" alt="" />
        <p className="flex text-sm pt-8">Get Our Mobile Apps On</p>
        <a target="blank" href="https://play.google.com/store/apps/details?id=id.great.app">
          <img src="/img/googleplay-badge.png" className="cursor-pointer items-center h-20 pt-4" alt="" />
        </a>
      </div>

      <div className="flex flex-col items-center p-12">
        <p className="font-medium pb-6">ARTICLES</p>
        <p className="font-medium pb-6">CAREERS</p>
        <p className="font-medium pb-6">MICE</p>
        <p className="font-medium pb-6">FAQ</p>
        <p className="font-medium pb-6 whitespace-nowrap">CUSTOMER SERVICE</p>
        <p className="font-medium whitespace-nowrap">PRIVACY POLICY</p>
      </div>

      <div className="pb-10 flex flex-col items-center">
        <p className="font-medium pb-6">Social Media</p>
        <div className="flex items-center">
          <a target="blank" href="http://instagram.com/haidafam">
            <img className="pr-2" src="/img/instagram-icon.svg" alt="" />
          </a>
          <div>
            <img className="pl-2" src="/img/facebook-icon.svg" alt="" />
          </div>
        </div>
      </div>

      <div className="flex items-center justify-center pb-10">
        <p className="text-xs font-light whitespace-nowrap">a Subsidiary Company of</p>
        <a target="blank" href="https://www.dafamproperty.com/">
          <img src="/img/dafamgroupbanner.svg" alt="" />
        </a>
      </div>

      <div className="h-px bg-gray-400 w-full" />

      <p className="justify-center text-xs font-hairline w-full flex py-6">
        Copyright © 2020 Dafam Hotels. All rights reserved.
      </p>
    </footer>
  );
}

function FooterDesktop() {
  return (
    <footer className="w-full footer px-16 pt-6 pfooter">
      <div className="flex items-center justify-between">
        <img src={process.env.LOGO_WHITE || '/img/logo-primary.svg'} className="flex items-center h-8 w-auto" alt="" />
        <div className="flex items-center mr-1">
          <p className="flex text-sm">Get Our Mobile Apps On</p>
          <a target="blank" href="https://play.google.com/store/apps/details?id=id.great.app">
            <img src="/img/googleplay-badge.png" className="flex items-center h-12 cursor-pointer" alt="" />
          </a>
        </div>
      </div>

      <div className="flex py-10 text-sm justify-between">
        <div className="flex flex-col">
          <p className="font-medium pb-4">ARTICLES</p>
          <p className="font-medium pb-4">CAREERS</p>
          <p className="font-medium pb-4">MICE</p>
          <p className="font-medium pb-4">FAQ</p>
          <p className="font-medium pb-4 whitespace-no-wrap">CUSTOMER SERVICE</p>
          <p className="font-medium pb-4">PRIVACY POLICY</p>
        </div>

        <div className="flex flex-col w-1/3">
          <div className="pb-6">
            <p className="font-medium pb-2">Head Office</p>
            <p className="p2footer text-xs font-light">
              Menara Suara Merdeka 11th Floor Jalan Pandanaran No. 30, Semarang 50134 | Central Java - Indonesia Tel.
              +62 24 355 9111 Fax. +62 24 358 9111 Email: info@dafamhotels.com
            </p>
          </div>
          <div className="pb-6">
            <p className="font-medium pb-2">Jakarta Sales Office</p>
            <p className="p2footer text-xs font-light">
              Dafam Express Jaksa Jakarta, Ground Floor Jalan Jaksa No. 27-29 Kebon Sirih, Jakarta Pusat 10340 | DKI
              Jakarta - Indonesia
            </p>
            <p className="p2footer text-xs font-light">Tel.+62 21 390 5977 Fax. +62 21 310 2228</p>
          </div>
          <div className="pb-6">
            <p className="font-medium pb-2">Bali Sales Office</p>
            <p className="p2footer text-xs font-light">
              Hotel Dafam Savvoya Seminyak Bali 4th Floor Jalan Mertanadi No. 14, Seminyak, Kuta 80361 | Bali -
              Indonesia Tel.+62 361 4741 760 Fax. +62 361 738 121
            </p>
          </div>
        </div>

        <div className="flex flex-col">
          <div className="pb-10">
            <p className="font-medium">Subscribe To Our Newsletter</p>
            <div className="w-80 newsteteller-nav mt-6 rounded-lg flex justify-between">
              <img className="pl-4" src="/img/mail-icon.svg" />
              <input
                placeholder="Enter your email"
                type="text"
                className="bg-transparent ml-2 focus:outline-none justify-center bg-none"
              />
              <button className=" z-50 submit-but p-2 w-24 ml-2 rounded-lg justify-center focus:outline-none font-semibold">
                SUBMIT
              </button>
            </div>
          </div>

          <div className="w-2/3 pb-10">
            <p className="font-medium pb-2">Social Media</p>
            <div className="flex pl-3">
              <a target="blank" href="http://instagram.com/haidafam">
                <img className="pr-2 cursor-pointer" src="/img/instagram-icon.svg" />
              </a>
              <a target="blank" href="https://www.facebook.com/DafamHotelManagement/">
                <img className="pl-2 cursor-pointer" src="/img/facebook-icon.svg" />
              </a>
            </div>
          </div>

          <div className="flex items-center cursor-pointer">
            <p className="text-xs font-light">a Subsidiary Company of</p>
            <a target="blank" href="https://www.dafamproperty.com/">
              <img src="/img/dafamgroupbanner.svg" />
            </a>
          </div>
        </div>
      </div>

      <div className="h-px bg-gray-400 w-full" />

      <p className="p2footer justify-center text-xs font-hairline w-full flex py-3">
        Copyright © 2020 Dafam Hotels. All rights reserved.
      </p>
    </footer>
  );
}

export { FooterDesktop, FooterMobile };
